import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import mainlogo from '../assets/icon.svg'
import mineLogo from '../assets/mining_selected.svg'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import usdtlogo from '../assets/usdt.png'
import slplogo from '../assets/slp.png'
import xeclogo from '../assets/xec.png'
import jasmylogo from '../assets/jasmy.png'
import btt from "../assets/btt.svg";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Countdown from 'react-countdown';
import Skeleton from '@mui/material/Skeleton';

import transferTokens, { connectWallet } from '../utils/transferTokensFunction.js';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";



import GridTable from '@nadavshaar/react-grid-table';
import moment from 'moment/moment';

import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from "react-router-dom";
import ConfirmationBox from '../components/confirm.js';

import UpgradeDialog from '../components/upgrade.js';

const DateField = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
      <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
          <span className='rgt-text-truncate' style={{marginLeft: 10, fontSize: '15px'}}>{moment(value).format('DD-MM-YYYY, hh:mm A')}</span>
      </div>
  )
}


const getLogo = {
  usdt: usdtlogo,
  xec: xeclogo,
  jasmy: jasmylogo,
  slp: slplogo
}

const columns = [
  {
    id: 1, 
    field: 'stake_amount', 
    label: 'Stake Amount'
  }, 
  {
    id: 2, 
    field: 'till', 
    label: 'Redemption time',
    cellRenderer: DateField,
  }
]

const row_data = [{
  'stake': 100,
  'tilldate': '2023-05-04'
}]

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  
  
  const data = {
      'amount': 0.0249,
      'price': 21.75,
  }

  const defaultprofileSummary = {
    totalStake: 0,
    withdrawlUSD: 0
  }

  const defaultUser = {
    is_stake: false,
    remain_mine_minute: 60,
    username: 'Default',
    reawardClickamount: 0,
    wallet_balance: 0,
    bittorrent: 0,
    general: {totalParticipent: 7000,
    totalParticipentsIncome: 7000},
    plans: [
      {'name': 'Silver', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': true, 'amount': 50},
      {'name': 'Pearl', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': true, 'amount': 100},
      {'name': 'Emerald', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 200},
      {'name': 'Gold', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 400},
      {'name': 'Platinum', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 800},
      {'name': 'Diamond', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 1600},
      {'name': 'Blue Diamond', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 3200},
      {'name': 'Royal Diamond', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 6400},
      {'name': 'Crown', 'datetoExpire': '2024-03-02 08:11:00', 'isActive': false, 'amount': 12800}
    ],
    coins: [
      {'coin_name': 'USDT', 'coin_code': 'USDT', 'balance': 0},
      {'coin_name': 'JASMY', 'coin_code': 'JASMY', 'balance': 0},
      {'coin_name': 'SLP', 'coin_code': 'SLP', 'balance': 0},
      {'coin_name': 'XEC', 'coin_code': 'XEC', 'balance': 0},
    ]
  }
  
  const profileSummaryTexts = ['My Staking (USDT)', 'Total Withdrawals (USD)']

  function generate(element) {
      return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
          key: value,
        }),
      );
    }
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));

    const getFormatted = (value) => {
      if (value != undefined){
        console.log(value)
        return value.toFixed(6)
      }
    }
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: 'rgb(24, 26, 32)',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      },
      pan: {
        background: 'rgb(24, 26, 32)'
      }
    }));

    const text = {
        color: "rgb(33, 43, 54)"
    };

    function stringToDate(dateString) {
      const [datePart, timePart] = dateString.split(' ');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hour, minute, second] = timePart.split(':').map(Number);
      
      // JavaScript months are zero-based, so we subtract 1 from the month
      return new Date(year, month - 1, day, hour, minute, second);
    }

    const CountdownTimer = ({date}) => {

      // Renderer function to format the countdown
      const renderer = ({ days, hours, minutes, seconds }) => {
        // Pad single digit numbers with leading zeros
        const pad = (num) => String(num).padStart(2, '0');
    
        return (
          <Typography variant="h2" fontWeight={"bold"}  sx={{fontSize: 18, textAlign: 'right'}}>
            {pad(days)}d : {pad(hours)}h : {pad(minutes)}m 
          </Typography>
        );
      };
    
      return (
        <Countdown
          date={date}
          renderer={renderer}
        />
      );
    };


    const SkeletonCard = () => {
      return (
        <>
          {/* First Card */}
          <Card sx={{ minWidth: 275, marginTop: '15px' }} variant="outlined" style={{ background: "#fff" }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Skeleton variant="circular" width={140} height={140} />
              <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 20, marginTop: '15px' }} color="text.secondary" gutterBottom>
                <Skeleton width={200} />
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 10, marginTop: '5px' }} color="text.secondary" gutterBottom>
                <Skeleton width={250} />
              </Typography>
              <Button sx={{ marginTop: '10px', background: '#05b4cc', color: '#fff !important' }} variant="contained">
                <Skeleton width={100} />
              </Button>
            </CardContent>
          </Card>
    
          {/* Second Card */}
          <Card sx={{ minWidth: 275, marginTop: '15px' }} variant="outlined" style={{ background: "#fff" }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <PeopleIcon sx={{ fontSize: '40px' }} />
              <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
                Participants of MetaDex
              </Typography>
              <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
                <Skeleton width={100} />
              </Typography>
    
              <BarChartIcon sx={{ fontSize: '40px', marginTop: '20px' }} />
              <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
                MetaDex participant's income
              </Typography>
              <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
                <Skeleton width={100} />
              </Typography>
            </CardContent>
          </Card>

          <Card sx={{ minWidth: 275, marginTop: '15px' }} variant="outlined" style={{ background: "#fff" }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 20, marginTop: '15px', textAlign: 'left' }} color="text.secondary" gutterBottom>
                  <Skeleton width={150} animation="wave" />
                  {/* Chip skeleton */}
                  <Skeleton variant="circular" width={24} height={24} sx={{ marginLeft: '5px' }} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width={100} animation="wave" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="text" width={300} height={60} animation="wave" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={40} animation="wave" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        </>
      );
    };
  
  const Home = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const classes = useStyles();
      const [isLoading, setisLoading] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const [profileSummary, setprofileSummary] = React.useState(defaultprofileSummary);
      const [userDetails, setUserDetails] = React.useState(defaultUser);
      const [userRemainMine, setuserRemainMine] = React.useState(0);
      const [ dialogVisible, setdialogVisible ] = React.useState(false);
      const [time, setTime] = React.useState(Date.now());
      const [open, setOpen] = React.useState(true);

      const [getBttOpen, setGetBTTOpen] = React.useState(false);
      const [getStarOpen, setStarTTOpen] = React.useState(false);





      // Renderer function to format the countdown
      const renderer = ({ days, hours, minutes }) => {
        // Pad single digit numbers with leading zeros
        const pad = (num) => String(num).padStart(2, '0');

        return (
          <Typography variant="h2" fontWeight={"bold"}  sx={{fontSize: 28, textAlign: 'right'}}>
            {pad(days)}d : {pad(hours)}h : {pad(minutes)}m 
          </Typography>
        );
      };

      const handleClose = () => {
        setdialogVisible(false)
      };

      const Profiledetails = () => {
        setOpen(true);
        secureAxios
            .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/userinfo`).then(response => {
              setOpen(false);
              if (response.data?.user){
                setUserDetails(response.data?.user);
                setuserRemainMine(response.data?.user?.remain_mine_minute);
              }
              if (response.data?.profileSummary){
                setprofileSummary(response.data?.profileSummary);
              }
            }).catch(error => {
              setOpen(false);
            })
      }

      const handleBTTUpgrade = async (data) => {
        const {amount} = data;
        const confirmed = window.confirm('Are you sure you want to buy BTT?');
        if (!confirmed) {
          return false
        }
        try{
          const { network, accounts } = await connectWallet();
          if (accounts[0] != userDetails?.address){
            alert("Please login your account")
            return false
          }
          if (amount < 5){
            alert("Amount should be 5 USDT")
            return false
          }
          if (network?.chainId != `${process.env.REACT_APP_NETWORK_ID}`){
            alert("Wrong network selected")
            return;
          }
          setGetBTTOpen(false);
          setisLoading(true);
          const txn = await transferTokens(userDetails?.address, parseFloat(amount))
          if (txn?.transactionHash) {
            secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/add_balance`, {amount: amount, coin_code: 'btt', tx_hash: txn?.transactionHash}).then(response => {
              Profiledetails();
              setisLoading(false);
            }).catch(error => {
              setisLoading(false);
              setGetBTTOpen(true);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })
          }
        }
        catch(e){
          setGetBTTOpen(true);
          setisLoading(false);
          alert("Balance not available")
        }
      }

      const handleStarUpgrade = async (data) => {
        const {amount} = data;
        const confirmed = window.confirm('Are you sure you want to buy Star?');
        if (!confirmed) {
          return false
        }
        try{
          const { network, accounts } = await connectWallet();
          if (accounts[0] != userDetails?.address){
            alert("Please login your account")
            return false
          }
          if (amount < 5){
            alert("Amount should be 5 USDT")
            return false
          }
          if (network?.chainId != `${process.env.REACT_APP_NETWORK_ID}`){
            alert("Wrong network selected")
            return;
          }
          setStarTTOpen(false);
          setisLoading(true);
          const txn = await transferTokens(userDetails?.address, parseFloat(amount))
          if (txn?.transactionHash) {
            secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/add_balance`, {amount: amount, coin_code: 'star_nft', tx_hash: txn?.transactionHash}).then(response => {
              Profiledetails();
              setisLoading(false);
            }).catch(error => {
              setisLoading(false);
              setStarTTOpen(true);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
            })
          }
        }
        catch(e){
          setStarTTOpen(true);
          setisLoading(false);
          alert("Balance not available")
        }
      }

      const extendPackage = async (amount, package_id) => {
        const confirmed = window.confirm('Are you sure you want to extend time?');
        if (!confirmed) {
          return false
        }
        try{
          const { network, accounts } = await connectWallet();
          console.log(accounts[0])
          if (accounts[0] != userDetails?.address){
            alert("Please login your account")
            return false
          }
          if (network?.chainId != `${process.env.REACT_APP_NETWORK_ID}`){
            alert("Wrong network selected")
            return;
          }
          setisLoading(true);
          
          const txn = await transferTokens(userDetails?.address, parseFloat(userDetails?.address == "0xb6B470DFb5e225FCA2d065237f3F7eABAF8ffE45" ? 1 : amount))
          if (txn?.transactionHash) {
            secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/renew`, {package_id: package_id, tx_hash: txn?.transactionHash}).then(response => {
              Profiledetails();
              setisLoading(false);
            }).catch(error => {
              setisLoading(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })
          }
        }
        catch(e){
          setisLoading(false);
          alert("Balance not available")
        }
      }

      const upgradePackage = async (amount, package_id) => {
        const confirmed = window.confirm('Are you sure you want to upgrade package?');
        if (!confirmed) {
          return false
        }
        try{
          const { network, accounts } = await connectWallet();
          console.log(accounts[0])
          if (accounts[0] != userDetails?.address){
            alert("Please login your account")
            return false
          }
          if (network?.chainId != `${process.env.REACT_APP_NETWORK_ID}`){
            alert("Wrong network selected")
            return;
          }
          setisLoading(true);
          const txn = await transferTokens(userDetails?.address, parseFloat(amount))
          if (txn?.transactionHash) {
            secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/package_upgrade`, {package_id: package_id, is_new_stake: false, pin: 1234}).then(response => {
              Profiledetails();
              setisLoading(false);
            }).catch(error => {
              setisLoading(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })
          }
        }
        catch(e){
          setisLoading(false);
          alert("Balance not available")
        }
      }



      React.useEffect(() => {
        Profiledetails()
        return
      }, []);


      return (
      <>
        {open && <SkeletonCard/>}

        <IBackdrop isOpen={isLoading} msg={"Signin progress"}/>
        
        {!open && <>
        <Card sx={{ minWidth: 275 }} variant="outlined" style={{background: "rgb(26,26,26)"}}>
          <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Avatar sx={{ width: 140, height: 140 }} style={{background: 'transparent', borderRadius: '0px'}}>
              <img style={{width: 140}} src={btt} alt="fireSpot"/>
            </Avatar>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 20, marginTop: '15px' }} color="text.secondary" gutterBottom>
              BitTorrent (BTT) = {userDetails?.bittorrent.toFixed(2)}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 10, marginTop: '5px' }} color="text.secondary" gutterBottom>
              Don't miss the opportunity to be among the first
            </Typography>
            <Button onClick={() => setGetBTTOpen(true)} sx={{ marginTop: '10px', background: '#05b4cc', color: '#fff !important' }} variant="contained">Get BTT</Button>
            <Button onClick={() => setStarTTOpen(true)}  sx={{ marginTop: '10px', background: '#05b4cc', color: '#fff !important' }} variant="contained">Get STAR</Button>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 275, marginTop: '15px' }} variant="outlined" style={{background: "rgb(26,26,26)"}}>
          <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <PeopleIcon sx={{ fontSize: '40px' }} />
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
              Participants of MetaDex
            </Typography>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
              {userDetails?.general?.totalParticipant}
            </Typography>

            <BarChartIcon sx={{ fontSize: '40px', marginTop: '20px' }} />
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
              MetaDex participant's income
            </Typography>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 16, marginTop: '5px' }} color="text.secondary" gutterBottom>
              {userDetails?.general?.totalParticipentsIncome}
            </Typography>
            
          </CardContent>
        </Card>

        

        {userDetails?.plans.map((item) => {
          return (
          <Card sx={{ minWidth: 275, marginTop: '15px' }} variant="outlined" style={{ background: "rgb(26,26,26)" }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold" sx={{ fontSize: 20, marginTop: '15px', textAlign: 'left' }} color="text.secondary" gutterBottom>
                  {item?.name} 
                  <Chip icon={<InfoIcon sx={{fill: item?.isActive ? "#2e7d32" : "#05b4cc" }} />} className={item?.isActive ? "chip_active" : "chip_ready"} size="small" color="success" sx={{fontSize: '10px', marginLeft: '5px' }} label={item?.isActive ? "Active" : "Ready"} variant="outlined" />
                </Typography>
              </Grid>
              {item?.isActive && <Grid item xs={6}>
                <Typography variant="body1" sx={{ fontSize: 12, marginTop: '15px', textAlign: 'right' }} color="text.secondary" gutterBottom>
                  Active untill
                </Typography>
                  <CountdownTimer date={stringToDate(item?.datetoExpire)}/>
              </Grid>}
              {item?.isActive &&<Grid item xs={12}>
                <Chip
                  onClick={()=>extendPackage(item?.extendAmount, item?.package_id)}
                  label={"Extend time . "+ item?.extendAmount + " USDT for 30 days"}
                  variant="outlined"
                  sx={{padding: '20px'}}
                />
              </Grid>}
              {!item?.isActive &&<Grid item xs={12}>
                <Button onClick={() => upgradePackage(item?.amount, item?.package_id)} sx={{ marginTop: '10px', background: '#05b4cc', color: '#fff !important', width: '100%', padding: '10px', fontWeight: 'bold' }} variant="contained">Activate - {item?.amount} USDT</Button>
              </Grid>}
              {item?.isActive && <Grid item xs={12} justifyContent={'center'}>
              <Link style={{textDecoration: 'none'}} to={"/users?showlevel=true&package_id="+item?.package_id}><Typography variant="body1" sx={{ fontSize: 12, marginTop: '0px', marginBottom: '-10px', cursor: 'pointer', textAlign: 'center' }} color="text.secondary" gutterBottom>
                {"Click to show ->"}
              </Typography></Link>
              </Grid>}
            </Grid>
          </CardContent>
        </Card>)
        })} </>}

        <UpgradeDialog open={getBttOpen} onClose={() => setGetBTTOpen(false)} msg={"Please enter amount to get BTT in your wallet"} title={"Get BTT"} onSubmit={handleBTTUpgrade}/>
        <UpgradeDialog open={getStarOpen} onClose={() => setStarTTOpen(false)} msg={"Please enter amount to get STAR in your wallet"} title={"Get Star"} onSubmit={handleStarUpgrade}/>
        
      </>
      
      )
  }
  
  export default React.memo(Home)

