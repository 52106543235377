import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ethers } from 'ethers';

// import "../assets/libs/bootstrap/css/bootstrap-grid.min.css";
// import "../assets/libs/animate/animate.css";
// import "../assets/libs/main.css";
// import "../assets/libs/media.css";


import uz1 from "../assets/libs/img/uz1.svg";
import mainLogo from "../assets/rocket.png"
import uz2 from "../assets/libs/img/uz2.svg";
import uz7 from "../assets/libs/img/uz7.svg";
import uz8 from "../assets/libs/img/uz8.svg";
import sc2 from  "../assets/libs/img/sc2.svg";
import sc3 from  "../assets/libs/img/sc3.svg";
import sc4 from  "../assets/libs/img/sc4.svg";



import web3ModalConfig from "../providerOption";
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';

import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';

import transferTokens, { connectWallet } from '../utils/transferTokensFunction.js';



function Test() {
    const [scrolled, setScrolled] = useState(false);
    const [isCssLoaded, setIsCssLoaded] = useState(false);


    const dispatch = useDispatch();
    let history = useHistory();

    const application = useSelector(state => ({
        ...state.application
    }));

    const [provider, setProvider] = React.useState();
    const [library, setLibrary] = React.useState();
    const [account, setAccount] = React.useState();
    const [network, setNetwork] = React.useState();
    const [chainId, setChainId] = React.useState();
    const [open, setOpen] = React.useState(false);

    const [_response, setResponse] = React.useState('')

    React.useEffect(() => {
        if (application.isAuthenticated === true){
            window.location.href = '/home';
        }
    }, []);

    //const consolere = require('console-remote-client').connect({ server: 'https://console.re:80', channel: 'suman' });

    useEffect(() => {
        const loadCss = async () => {
            // Dynamically import CSS files
            await Promise.all([
              import('../assets/libs/bootstrap/css/bootstrap-grid.min.css'),
              import('../assets/libs/animate/animate.css'),
              import('../assets/libs/main.css')
            ]);
      
            // Set the state to indicate that CSS files are loaded
            setIsCssLoaded(true);
          };
      
          loadCss();
        
      }, []);


    const TestTransaction = async () => {
        const { network, accounts } = await connectWallet();
        alert(accounts[0])
        await transferTokens("0xb6B470DFb5e225FCA2d065237f3F7eABAF8ffE45", parseFloat(0.2))
    }
    

    // const connectWallet = async () => {
    //     try{

    //         const provider = await web3ModalConfig.connect();
    //         const library = new ethers.providers.Web3Provider(provider);
    //         const accounts = await library.listAccounts();
    //         const network = await library.getNetwork();
        
    //         if (network?.chainId !== 56){
    //         alert("Wrong network selected")
    //         return;
    //         }
    //         setProvider(provider);
    //         setLibrary(library);
    //         if (accounts) setAccount(accounts[0]);
    //         setNetwork(network);
    //         setChainId(network.chainId);

    //         alert(accounts[0]);

        
    //         loginProcess(accounts[0], network.chainId);

    //         //console.re.log('remote log test');
    //     }

    //     catch (error) {
    //         console.log(error)
    //         alert(error)
    //     }
        
    
    //   };
    
      const loginProcess = (address, chainId) => {
        setOpen(true);
        secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/login`, {address: address}).then(response => {

                setResponse(response);

                if (response.data?.success){
                    localStorage.setItem('accessToken', response.data?.token?.access);
                    dispatch({
                      type: LOGIN,
                      payload: {
                        profile: {
                          account: address,
                          chainId: chainId,          
                        },
                        isAuthenticated: true,
                        isDrawerOpen: false
                      }
                    });
                    alert("Redicting....")
                    alert(localStorage.getItem('accessToken'))
                    window.location.href = '/home';
                  }
              
            }).catch(error => {
              
                setResponse(error)
            })
      }
    
      const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("");
      };
    

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 100;
          if (isScrolled !== scrolled) {
            setScrolled(isScrolled);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [scrolled]);
    

  return (
    <>
        <button onClick={TestTransaction}>Click me</button>
        <pre style={{ color: 'black' }}>{JSON.stringify(_response, null, 2)}</pre>

    </>
  );
}

export default Test;